<template>
  <v-container
    v-if="pageLoading"
    fluid
    class="d-flex justify-center align-center loading-container"
  >
    <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
  </v-container>
  <v-container v-else>
    <v-row>
      <v-col lg="6" sm="12">
        <template v-for="(item, index) in cards">
          <div class="ma-2 pb-2" :key="index">
            <bz-card
              :title="item.title"
              :isActive="item.isActive"
              :link="item.link"
              :subtitle="item.subtitle"
            />
          </div>
        </template>
      </v-col>
      <v-col class="ml-12">
        <bz-share-card :code="code" />
      </v-col>
    </v-row>

    <router-view></router-view>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import BzCard from "../../../components/BzCard";
import BzShareCard from "../../../components/BzShareCard";
import { eventBus } from "../../../../main";
import { Current_Account_ID } from "../../../../api";

export default {
  components: {
    BzCard,
    BzShareCard
  },

  data() {
    return {
      code: "ENEL3698",
      currentAccountId: "",
      pageLoading: false,
      isPushActive: false,
      isAnnounceActive: false,
      cards: [],
      value: 0
    };
  },
  async created() {
    this.pageLoading = true;
    let allAccounts = await this.getAllAccounts();

    if (allAccounts[0].modules.length > 0) {
      let { beezInCode } = allAccounts[0].modules[1];

      if (beezInCode) {
        this.code = beezInCode;
      }
    }

    await this.getCommunicationModulePermissions();
    await this.getAllAccountModules();
    this.pageLoading = false;
  },
  computed: {
    ...mapFields("modules", [
      "currentModule",
      "currentLink",
      "currentModuleMembers",
      "currentModulePermissionStatus",
      "currentAccountModuleId"
    ]),
    ...mapFields("admin", { accountModules: "accountModules" })
  },
  methods: {
    ...mapActions("modules", {
      fetchAccountModule: "fetchAccountModule",
      fetchAccountModuleMembers: "fetchAccountModuleMembers",
      storeCurrentModuleId: "storeCurrentModuleId",
      getCommunicationModule: "getCommunicationModule"
    }),
    ...mapActions("admin", {
      getAllAccounts: "getAllAccounts"
    }),
    async getCommunicationModulePermissions() {
      this.currentAccountId = localStorage.getItem(Current_Account_ID);
      try {
        const actives = await this.getCommunicationModule({
          accountId: this.currentAccountId
        });

        this.isPushActive = this.currentModulePermissionStatus.data[0].pushStatus;
        this.isAnnounceActive = this.currentModulePermissionStatus.data[0].announcementStatus;

        this.cards.push(
          {
            title: "Push Notifications",
            isActive: this.isPushActive,
            link: "push-notification",
            subtitle: "is a quick note to share to your users"
          },
          {
            title: "Announcement",
            isActive: this.isAnnounceActive,
            link: "announcement",
            subtitle: "is more formal and more informative to send to users"
          }
        );
      } catch (error) {
        this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = "";
        }, 2000);
      }
    },
    async getAllAccountModules() {
      // on event call get the Parameter saying onRefresh
      /*First Set the Account Id  */
      this.currentAccountId = localStorage.getItem(Current_Account_ID);
      if (this.currentAccountModuleId === null && this.currentLink !== null) {
        this.value = 25;

        try {
          this.value = 75;
          const modules = await this.fetchAccountModule({
            accountid: this.currentAccountId
          });
          this.storeCurrentModuleId({ id: modules[1]._id });
          this.value = 99;
          this.$router.push({ name: this.currentLink });
        } catch (error) {
          this.notify("error", error.message);
        }
      } else {
        try {
          const modules = await this.fetchAccountModule({
            accountid: this.currentAccountId
          });

          /* Filter the Account Module Id to pass to User Table*/
          this.storeCurrentModuleId({ id: modules[1]._id });
        } catch (error) {
          this.notify("error", error.message);
        }
      }
    }
  }
};
</script>
<style scoped>
.beezin-code {
  box-shadow: 0px 0px 99px #00000036;
}
.v-progress-circular {
  margin: 1rem;
}
.loading-container {
  min-height: 60vh;
}
</style>
